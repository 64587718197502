<template xmlns="http://www.w3.org/1999/html">
  <div class="rlzy">
    <div class="header">
      <div class="h1">人力资源</div>
      <div class="h2">人才计划 助力企业经济腾飞</div>
    </div>
    <div class="content">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="mbx">
        <el-breadcrumb-item  :to="{ path: '/rlzy' }">人力资源</el-breadcrumb-item>
        <el-breadcrumb-item >查看</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="tabs flex">
        <div class=" span" v-for="(li,index) in ul " @click="tabc(li,index)" :key="index"><span :class="{active:activeName==index}">{{li.text}}</span></div>
      </div>
      <div v-show="activeName==0">
        <div class="search">
          <avue-search :option="option"   @change="loadlist(true)"
                       v-model="form">
          </avue-search>
          <div style="text-align: right;padding: 0 20px 20px 0">
            <el-button type="primary" size="mini" @click="loadlist(true)">筛选</el-button>
            <el-button  size="mini" @click="reset">重置</el-button>
          </div>
        </div>
        <div class="items">
          <div class="list"  v-for="(it,index) in  arr" @click="gotoxq(it)" :key="index">
              <div class="h1">{{it.title}}</div>
              <div class="h2 flex">
                <div class="money">{{it.minSalary}}-{{it.maxSalary}}元/月</div>
                <div class="flex-1 zhong">
                  <div>{{it.categoryName}}</div>
                  <div>{{it.jobName}}  |  学历：   {{it.eduBack}}    | 工作经验： {{it.jobYears}}</div>
                </div>
                <div>
                  <el-button type="danger" @click.stop="shenqing(it)">申请</el-button>
                </div>
              </div>
              <div class="h3">{{it.welfareNames}}</div>
          </div>
        </div>
        <div class="fenye">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              layout="total, sizes, prev, pager, next, jumper"
              :current-page="page.current"
              :page-sizes="[10, 50, 100, 200]"
              :page-size="page.size"
              :total="page.total">
          </el-pagination>
        </div>
      </div>
      <div v-show="activeName==1">
        <div class="search">
          <avue-search :option="option1" @change="loadlist1(true)"
                       v-model="form1">
          </avue-search>
          <div style="text-align: right;padding: 0 20px 20px 0">
            <el-button type="primary" size="mini" @click="loadlist1(true)">筛选</el-button>
            <el-button  size="mini" @click="reset1">重置</el-button>
          </div>
        </div>
        <div class="items">
          <div class="flex list" v-for="(it,index) in  arr1" :key="index" @click="gotoxq1(it)">
              <div> <el-avatar :size="50" :src="it.avatar||tou"></el-avatar></div>
            <div style="margin-left: 30px">
              <div class="name">{{it.name}}</div>
              <div class="miaoshu"><span>{{it.province}}{{it.city}}{{it.area}}</span><span>{{it.industry}}</span><span>{{it.degree}}</span></div>
              <div style="margin: 5px 0">简介:</div>
              <div class="ellipsis2"  style="padding: 0 15px">{{it.content}}</div>
            </div>
          </div>
        </div>
        <div class="fenye">
          <el-pagination
              @size-change="handleSizeChange1"
              @current-change="handleCurrentChange1"
              layout="total, sizes, prev, pager, next, jumper"
              :current-page="page1.current"
              :page-sizes="[10, 50, 100, 200]"
              :page-size="page1.size"
              :total="page1.total">
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog title="申请" width="600px" append-to-body :visible.sync="dialogTableVisible">
      <avue-form :option="sqoption" v-model="userForm" @submit="submitSwitch"></avue-form>
    </el-dialog>
  </div>
</template>

<script>
import {fiveyears} from "../../utils";
import {humanpageindex, humanpersonnelpage, humanrecruitapply, humanrecruitpage} from "../../request/moudle/rlzy";
import Cookies from "js-cookie";
export default {
  name: "index",
  data(){
    return {
      itid:'',
      dialogTableVisible:false,
      tou:'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
      form: {
       category: this.$route.query.id,
      },
      userForm:{},
      form1: {
      },
      page:{
        current:1,
        size:10,
        total:0
      },
      page1:{
        current:1,
        size:10,
        total:0
      },
      sqoption:{
        column: [
          {
            label: '申请人',
            prop: 'name',
            span: 24,
            type: 'input'
          },
          {
            label: '简介',
            prop: 'detail',
            span: 24,
            type: 'textarea'
          },
          {
            label: '联系电话',
            prop: 'phone',
            span: 24,
            type: 'input'
          },
          {
            label: '简历附件',
            prop: 'files',
            type: 'upload',
            multiple: true,
            span: 24,
            limit: 1,
            dataType: 'string',
            propsHttp: {
              url: 'link',
              res: 'data'
            },
            headers: {
              'Authorization': `Basic ${Base64.encode(`pc:idsp-pc`)}`,
              'Tenant-Id': Cookies.get('Tenant-Id'),
              'Blade-Auth': 'bearer ' + Cookies.get('saber-access-token')
            },
            action:'/api/blade-resource/oss/endpoint/put-file'
          },
        ]
      },
      option: {
        column: [ {
          label: '行业类别',
          prop: 'category',
          props: {
            label: 'title',
            value: 'id',
          },
          multiple: false,
          dicData:[],
        },
          {
            label: '福利待遇',
            dicData:[],
            prop: 'welfare',
            multiple: false,
            props: {
              label: 'title',
              value: 'id'
            },
          },

          {
            label: '学历',
            dicData:[],
            prop: 'eduBack',
            multiple: false,
            props: {
              label: 'title',
              value: 'id'
            },
          },
          {
            label: '工作经验',
            dicData:[],
            prop: 'jobYears',
            multiple: false,
            props: {
              label: 'title',
              value: 'id'
            },
          }]
      },
      option1: {
        column: [ {
          label: '行业领域',
          prop: 'industry',
          props: {
            label: 'title',
            value: 'id',
          },
          multiple: false,
          dicData:[],
        },{
            label: '学历',
            dicData:[],
            prop: 'degree',
            multiple: false,
            props: {
              label: 'title',
              value: 'id'
            },
          }]
      },
      activeName:this.$route.query.type||'0',
      ul:[{text:'找工作'},{text:'政府人才名录'}],
      arr:[],
      arr1:[]
    }
  },
  created() {
    this.loadconfig()
    if(this.activeName==0){
      this.loadlist()
    }else{
      this.loadlist1()
    }

  },
  methods:{
    submitSwitch(form,done){
      this.userForm.recruitId=this.itid
      this.$api.rlzy.humanrecruitapply(this.userForm).then((res)=>{
        this.$message.success('提交成功')
        this.dialogTableVisible=false
        done()
      },()=>{
        done()
      })
    },
    shenqing(item){
      this.itid=item.id
      this.dialogTableVisible=true
    },
    gotoxq(item){
      this.$router.push({
        path:'/rlzyfind',
        query:{
          id:item.id
        }
      })
    },
    gotoxq1(item){
      console.log(item)
      this.$router.push({
        path:'/rlzypeople',
        query:{
          id:item.id
        }
      })
    },
    loadconfig(){
      this.$api.rlzy.humanpageindex().then((res)=>{
        const category=this.findObject(this.option.column, 'category')
        const welfare=this.findObject(this.option.column, 'welfare')
        const eduBack=this.findObject(this.option.column, 'eduBack')
        const jobYears=this.findObject(this.option.column, 'jobYears')
        const industry=this.findObject(this.option1.column, 'industry')
        const degree=this.findObject(this.option1.column, 'degree')
        category.dicData=res.data.recruitIndex.categoryList
        category.dicData.unshift({ title: '不限', id: '' })
        welfare.dicData=res.data.recruitIndex.welfareList
        welfare.dicData.unshift({ title: '不限', id: '' })
        eduBack.dicData=res.data.recruitIndex.eduBackList
        eduBack.dicData.unshift({ title: '不限', id: '' })
        jobYears.dicData=res.data.recruitIndex.jobYearList
        jobYears.dicData.unshift({ title: '不限', id: '' })
        industry.dicData=res.data.personnelIndex.industryList
        industry.dicData.unshift({ title: '不限', id: '' })
        degree.dicData=res.data.personnelIndex.eduBackList
        degree.dicData.unshift({ title: '不限', id: '' })
      })
    },
    tabc(li,index){
      this.activeName=index
      if(index==0){
        this.loadlist()
      }else{
        this.loadlist1()
      }
    },
    loadlist(bol){
      if(bol){
        this.page.current=1
      }
      const obj={
        ...this.form
      }
      obj.current=this.page.current
      obj.size=this.page.size
      this.$api.rlzy.humanrecruitpage(obj).then((res)=>{
        this.arr=res.data.records
        this.page.total=res.data.total
      })
    },
    reset(){
      for (const val of this.option.column){
        if(val.multiple){
          this.form[val.prop]=['']
        }else {
          this.form[val.prop]=''
        }
      }
    },
    handleSizeChange(val){
      this.page.size=val
      this.loadlist()
    },
    handleCurrentChange(val) {
      this.page.current=val
      this.loadlist()
    },
    loadlist1(bol){
      if(bol){
        this.page1.current=1
      }
      const obj={
        ...this.form1
      }
      obj.current=this.page1.current
      obj.size=this.page1.size
      this.$api.rlzy.humanpersonnelpage(obj).then((res)=>{
        this.arr1=res.data.records
        this.page1.total=res.data.total
      })
    },
    reset1(){
      for (const val of this.option1.column){
        if(val.multiple){
          this.form1[val.prop]=['']
        }else {
          this.form1[val.prop]=''
        }
      }
    },
    handleSizeChange1(val){
      this.page1.size=val
      this.loadlist1()
    },
    handleCurrentChange1(val) {
      this.page1.current=val
      this.loadlist1()
    },
  }
}
</script>

<style lang="scss" scoped>
.fenye{
  text-align: center;
  padding: 30px 0;
}
.name{
  font-weight: bolder;
  line-height: 30px;
}
.miaoshu{
  font-size: 12px;
  color: #C4C4C4;
  span{
    margin-right: 15px;
  }
}
  .rlzy{
    background: #F5F5F5;
    .header{
      height: 220px;
      background: url("../../assets/banner/rlzy.png") no-repeat;
      background-size: 100% 100%;
      padding-top: 90px;
      text-align: center;
      color: #fff;
      .h1{
        font-size: 42px;
        font-weight: 500;
      }
      .h2{
        font-size: 22px;
        line-height: 80px;
      }
    }
    .content{
      width: 1200px;
      margin: 0 auto;
      .mbx{
        margin: 25px 0;
      }
      .items{
        margin-top: 30px;
        .list{
          background: #fff;
          padding:20px 39px;
          margin-bottom: 1px;
          cursor: pointer;
        }
        .h1{
          font-size: 16px;
          font-weight: 550;
        }
        .h2{
          margin: 8px 0;
        }
        .money{
          color: red;
          width: 300px;
        }
        .h3{
          color: #0078F2;
        }
      }
      .tabs {
        border-bottom: 1px #D7D7D7 solid;
        background: #fff;
        text-align: center;
        span{
          font-weight: 550;
          padding:10px 52px;
          line-height: 39px;
        }
        .active{
          background:#E7BD88 100%;
          color: #fff;
        }
      }
      .search{
        background: #fff;
        padding: 43px;
        .shai{
          margin-bottom: 30px;
          margin-right: 20px;
        }
      }
    }

  }

</style>